@padding: 100px;
@green: #307c4c;
@maxwidth: 1400px;
@darkgray: #2c2c2c;
@borderwidth: 8px;

body {
	margin: 0;
	font-family: "Montserrat";
	background: darken(mix(rgb(40, 40, 134), @green, 50%), 25%);
}

.inner {
	max-width: @maxwidth;
	margin: auto;
	padding: 0 @padding;
}

.header {
	background: linear-gradient(0deg, transparent, @green);
	// background-image: url(../img/wave-background.png?as=webp);
	// background-size: cover;
	// background-position: bottom center;
	// background-repeat: no-repeat;
	box-sizing: border-box;
	// height: 70vh;
	height: 100vh;

	& .inner {
		position: relative;
	}

	& a {
		margin-top: 20px;
		color: white;
		// padding: 10px;
		display: block;
		width: fit-content;
	}

	& .logo-box {
		position: absolute;
		transform: translateY(-50%);
		// top: calc(50vh - 150px);
		top: 50vh;

		& img {
			height: 100px;
			user-select: none;
			pointer-events: none;
		}

		& .button {
			font-size: 18px;
			background: white;
			position: relative;
			width: fit-content;
			padding: 15px 25px;
			border-radius: 25px;
			margin-top: 40px;
			cursor: pointer;
			transition: box-shadow 0.1s ease, background 0.5s ease;
			user-select: none;
			color: black;
			text-decoration: none;

			&::before {
				background: linear-gradient(-27deg, #809cd7 0%, #a8d979 100%);
				content: "";
				position: absolute;
				inset: 0;
				border-radius: 25px;
				z-index: -1;
			}

			&:hover {
				box-shadow: 0 5px 5px fade(@darkgray, 20%);
				background: transparent;
			}
		}
	}

	& .demo {
		position: absolute;
		width: 120px;
		top: calc(50vh + 130px);
		right: 450px;
		z-index: 1;
		pointer-events: none;
		user-select: none;
	}

	& .phone-view {
		position: absolute;
		transform: translateY(-50%);
		top: 50vh;
		right: @padding;
		background: @darkgray;
		// outline: @borderwidth solid @darkgray;
		border-radius: 30px;
		height: fit-content;
		overflow: hidden;
		box-shadow: 0 12px 36px fade(black, 20%), 0 0 12px fade(black, 20%);

		& .phone {
			width: 350px;
			max-height: 700px;
			height: calc(100vh - 40px);
			margin: 0;
			padding: 0;
			display: block;
		}
	}
}

.popup {
	position: fixed;
	inset: 0;
	z-index: 2;
	padding: 20px;
	opacity: 1;
	transition: opacity 0.1s ease;

	& .background {
		position: fixed;
		inset: 0;
		backdrop-filter: blur(10px);
		background: fade(black, 80%);
	}

	& .close {
		width: 24px;
		height: 24px;
		position: absolute;
		right: 15px;
		top: 15px;
		background-image: url(../img/close.png?as=webp&width=24);
		background-repeat: no-repeat;
		background-position: center;
		cursor: pointer;
		padding: 5px;
		border-radius: 40px;
		transition: background-color 0.1s ease;

		&:hover {
			background-color: darken(white, 10%);
		}
	}

	& .box {
		z-index: 1;
		background: white;
		border-radius: 10px;
		padding: 20px;
		max-width: 800px;
		max-height: calc(100vh - 40px);
		height: fit-content;
		width: calc(100vw - 40px);
		// margin: 30px;
		left: 50vw;
		position: fixed;
		top: 50vh;
		transform: translate(-50%, -50%);
		box-sizing: border-box;
		padding-bottom: 0;
		overflow: auto;

		& h1,
		& h2,
		& h3 {
			margin: 0;
			margin-bottom: 10px;
			font-family: "Bebas Neue";
			letter-spacing: 3px;
		}

		& p {
			margin: 0;
			margin-bottom: 20px;
			font-size: 18px;
			line-height: 1.5;
		}
	}
}

.hidden {
	// display: none;
	opacity: 0;
	pointer-events: none;
}

@media screen and (max-width: 1200px) {
	.inner {
		padding: 0 calc(@padding / 2);
	}

	.header {
		& .phone-view {
			right: calc((@padding / 2) + @borderwidth);
		}
	}
}

@media screen and (max-width: 1000px) {
	.demo {
		display: none;
	}

	.inner {
		max-width: calc(@maxwidth / 2);
	}

	.header {
		& .inner {
			display: flex;
			justify-content: center;
		}

		& .logo-box {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}

	.phone-view {
		display: none;
	}
}

@media screen and (max-width: 500px) {
	.header {
		height: 100vh;
		// background-image: none;

		& .logo-box {
			& img {
				width: 100%;
				height: auto;
				padding: 30px;
				box-sizing: border-box;
			}
		}
	}
}
