body {
  background: #060c0f;
  margin: 0;
  font-family: Montserrat;
}

.inner {
  max-width: 1400px;
  margin: auto;
  padding: 0 100px;
}

.header {
  box-sizing: border-box;
  background: linear-gradient(0deg, #0000, #307c4c);
  height: 100vh;
}

.header .inner {
  position: relative;
}

.header a {
  color: #fff;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 20px;
  display: block;
}

.header .logo-box {
  position: absolute;
  top: 50vh;
  transform: translateY(-50%);
}

.header .logo-box img {
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  height: 100px;
}

.header .logo-box .button {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  color: #000;
  background: #fff;
  border-radius: 25px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 40px;
  padding: 15px 25px;
  font-size: 18px;
  text-decoration: none;
  transition: box-shadow .1s, background .5s;
  position: relative;
}

.header .logo-box .button:before {
  content: "";
  z-index: -1;
  background: linear-gradient(-27deg, #809cd7 0%, #a8d979 100%);
  border-radius: 25px;
  position: absolute;
  inset: 0;
}

.header .logo-box .button:hover {
  background: none;
  box-shadow: 0 5px 5px #2c2c2c33;
}

.header .demo {
  z-index: 1;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  width: 120px;
  position: absolute;
  top: calc(50vh + 130px);
  right: 450px;
}

.header .phone-view {
  background: #2c2c2c;
  border-radius: 30px;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  top: 50vh;
  right: 100px;
  overflow: hidden;
  transform: translateY(-50%);
  box-shadow: 0 12px 36px #0003, 0 0 12px #0003;
}

.header .phone-view .phone {
  width: 350px;
  height: calc(100vh - 40px);
  max-height: 700px;
  margin: 0;
  padding: 0;
  display: block;
}

.popup {
  z-index: 2;
  opacity: 1;
  padding: 20px;
  transition: opacity .1s;
  position: fixed;
  inset: 0;
}

.popup .background {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: #000c;
  position: fixed;
  inset: 0;
}

.popup .close {
  cursor: pointer;
  background-image: url("close.53973bd7.webp");
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 40px;
  width: 24px;
  height: 24px;
  padding: 5px;
  transition: background-color .1s;
  position: absolute;
  top: 15px;
  right: 15px;
}

.popup .close:hover {
  background-color: #e6e6e6;
}

.popup .box {
  z-index: 1;
  box-sizing: border-box;
  background: #fff;
  border-radius: 10px;
  width: calc(100vw - 40px);
  max-width: 800px;
  height: -moz-fit-content;
  height: fit-content;
  max-height: calc(100vh - 40px);
  padding: 20px 20px 0;
  position: fixed;
  top: 50vh;
  left: 50vw;
  overflow: auto;
  transform: translate(-50%, -50%);
}

.popup .box h1, .popup .box h2, .popup .box h3 {
  letter-spacing: 3px;
  margin: 0 0 10px;
  font-family: Bebas Neue;
}

.popup .box p {
  margin: 0 0 20px;
  font-size: 18px;
  line-height: 1.5;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

@media screen and (width <= 1200px) {
  .inner {
    padding: 0 50px;
  }

  .header .phone-view {
    right: 58px;
  }
}

@media screen and (width <= 1000px) {
  .demo {
    display: none;
  }

  .inner {
    max-width: 700px;
  }

  .header .inner {
    justify-content: center;
    display: flex;
  }

  .header .logo-box {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .phone-view {
    display: none;
  }
}

@media screen and (width <= 500px) {
  .header {
    height: 100vh;
  }

  .header .logo-box img {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    padding: 30px;
  }
}

/*# sourceMappingURL=index.f77b6927.css.map */
